<template>
  <div class="analytics">
    <v-form @submit.prevent="getData">
      <v-row class="pb-6 d-flex justify-center">
        <v-col cols="2" class="pt-2">
          <a-text-field
            label="SKU"
            v-model="$v.formInputSKU.$model"
            :validator="$v.formInputSKU"
            hint="ex.: 783291"
            type="number"
            @input="changeSKU"
          >
          </a-text-field>
        </v-col>
        <v-col cols="1" class="pt-2 ml-10">
          <v-hover v-slot="{ hover }">
            <v-btn
              type="submit"
              color="white--text"
              :style="{ 'background-color': hover ? '#31649B' : '#424242' }"
              class="mt-4 ml-n6"
              >Go !</v-btn
            >
          </v-hover>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="mt-6" v-if="this.show">
      <v-col cols="2">
        <p class="font-weight-bold">Descrição:</p>
      </v-col>
      <v-col cols="10" class="ml-n16">
        <p>{{ description }}</p>
      </v-col>
    </v-row>
    <v-row class="mt-0" v-if="this.show">
      <v-col cols="2">
        <p class="font-weight-bold">Categorias:</p>
      </v-col>
      <v-col cols="10" class="ml-n16">
        <p>{{ getCategoriesText(this.categories) }}</p>
      </v-col>
    </v-row>
    <PlanogramNodes
      v-if="this.show"
      :planogram="this.loadedPlanogram"
      :nodes="this.nodes"
      class="mt-n12"
    />
    <v-row class="d-flex justify-center mt-12 mb-2" v-if="this.show"
      ><h4>Vendas totais (unidades) : {{ sales }}</h4></v-row
    >
    <v-card
      class="d-flex align-center"
      color="white"
      style="width: 100%"
      elevation="1"
      v-if="this.show"
    >
      <v-col cols="12">
        <line-chart :data="lineChartData" :cats="categoriesChart" />
      </v-col>
    </v-card>
  </div>
</template>
<style scoped>
.counter {
  padding-left: 90px;
}

.potential-add {
  padding-right: 145px;
}

.diagram {
  visibility: hidden;
}
</style>
<script>
import ATextField from "@/components/ATextField.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AppError from "@/utils/AppError.js";
import PlanogramNodes from "@/components/PlanogramNodes.vue";
import LineChart from "@/components/charts/Line.vue";

export default {
  name: "Find-product",

  components: {
    PlanogramNodes,
    ATextField,
    LineChart,
  },

  mounted: function () {
    this.getStore();
    this.getLoadedPlanogram();
  },

  mixins: [validationMixin],

  validations: {
    // Form validations
    formInputSKU: { required },
  },

  data() {
    return {
      show: false,
      nodes: null,

      // Form inputs
      formInputSKU: null,
      loadedPlanogram: null,
      description: null,
      categories: null,
      sales: null,
      lineChartData: null,
      categoriesChart: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Code",
          align: "start",
          value: "code",
        },
        {
          text: "Category",
          value: "category",
        },
        {
          text: "Current location",
          value: "location",
        },
        {
          text: "New location",
          value: "newLocation",
        },
        {
          text: "Expected sales (k€)",
          value: "sales",
          filterable: true,
        },
      ];
    },
  },

  methods: {
    /**
     * Get the selected store.
     * @public
     */
    getStore() {
      this.store = JSON.parse(localStorage.getItem("store"));
    },
    /**
     * Get the loaded planogram.
     * @public
     */
    getLoadedPlanogram() {
      let url = this.$config.api.route.planogram;
      url = url.replace(":id", this.store.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.loadedPlanogram = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .then(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Get analytic data.
     * @public
     */
    getData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.categoriesChart = [
        "Jan2 2022",
        "Jan 16",
        "Jan 30",
        "Feb 13",
        "Feb 27",
        "Mar 13",
        "Mar 27",
        "Apr 10",
        "Apr 24",
        "May 8",
      ];
      this.description = "C-LAMEN SHOYU 300G";
      this.categories = ["A1M1", "GRAB1", "A1M1", "A1M1"];
      this.sales = 19.55;
      this.lineChartData = {
        data1: [1, 0, 0.5, 0, 1, 2.5, 1, 0, 3, 0],
      };
      this.nodes = [
        {
          color: "#0099ff",
          size: "50 50",
          pos: "50 50",
          category: "heatmap",
        },
        {
          color: "#0099ff",
          size: "50 50",
          pos: "-400 0",
          category: "heatmap",
        },
      ];
      this.show = true;
    },
    /**
     * Change sku.
     * @public
     */
    changeSKU() {
      this.show = false;
    },
    /**
     * Get users stores text formated.
     * @param {array} categories - The stores.
     * @public
     */
    getCategoriesText(categories) {
      if (categories != null) {
        var categoriesText = "";
        var i = 1;
        var numCategories = categories.length;
        categories.forEach((category) => {
          if (i == numCategories) {
            categoriesText += i + ": " + category + ";";
          } else {
            categoriesText += i + ": " + category + " , ";
          }
          i++;
        });
        return categoriesText;
      }
    },
  },
};
</script>
