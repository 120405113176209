<template>
  <v-text-field
    :outlined="outlined"
    v-bind:value="value"
    v-on="on"
    :type="type === 'password' ? (showValue ? 'text' : 'password') : type"
    :inputmode="inputmode"
    :error-messages="errors"
    :label="label"
    :placeholder="placeholder"
    :hint="hint"
    :readonly="readonly"
    hide-details="auto"
    class="a-text-field"
    :prepend-icon="prependIcon"
    :append-icon="
      type === 'password' ? (showValue ? 'mdi-eye' : 'mdi-eye-off') : appendIcon
    "
    @click:append="showValue = !showValue"
    @input="$emit('input', $event)"
  ></v-text-field>
</template>

<script>
/**
 * @vue-prop {String} value - Value
 * @vue-prop {Object} on - On
 * @vue-prop {Object} validator - Validator
 * @vue-prop {String} type - Type
 * @vue-prop {String} inputmode - Input Mode
 * @vue-prop {String} label - Label
 *  * @vue-prop {String} placeholder - Placeholder
 * @vue-prop {String} hint - Hint
 * @vue-prop {Boolean} readonly - Read only
 * @vue-prop {String} prependIcon - Icon
 * @vue-prop {String} appendIcon - Icon
 * @vue-computed {String} errors
 */

export default {
  name: "a-text-field",

  data() {
    return {
      showValue: false,
    };
  },

  props: {
    value: String,
    on: Object,
    validator: Object,
    type: String,
    inputmode: String,
    label: String,
    placeholder: String,
    hint: String,
    readonly: Boolean,
    prependIcon: String,
    appendIcon: String,
    outlined: String
  },

  computed: {
    errors() {
      const errors = [];

      if (!this.validator || !this.validator.$dirty) return errors;

      typeof this.validator.required !== "undefined" &&
        !this.validator.required &&
        errors.push(this.$t("errors.fields.REQUIRED_FIELD"));

      typeof this.validator.email !== "undefined" &&
        !this.validator.email &&
        errors.push(this.$t("errors.fields.INVALID_EMAIL"));

      return errors;
    },
  },
};
</script>
