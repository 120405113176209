<template>
  <div class="pt-12">
    <v-card style="border-radius: 30px" elevation="0" class="pa-10">
      <v-row class="d-flex justify-center mt-2">
        <v-col>
          <!-- Planogram -->
          <div
            :id="this.planogram.name"
            style="flex-grow: 1; height: 500px; border: solid 1px #9b9b9b"
          ></div>
          <!-- !Planogram -->
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<style scoped>
input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs {
  overflow: hidden;
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}
.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  background: #001523;
  cursor: pointer;
}
.tab-label:hover {
  background: #00406c;
}
.tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tab-content {
  max-height: 0;
  color: #2c3e50;
  background: white;
}
.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.tab-close:hover {
  background: #1a252f;
}

input:checked + .tab-label {
  background: #1a252f;
}
input:checked + .tab-label::after {
  transform: rotate(90deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
}
/* !Accordion styles */

.planogram {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.planogram-grid {
  width: 245px;
  margin-right: 2px;
  background-color: white;
  border: solid 1px black;
}

.shelves-palette {
  width: 245px;
  height: 120px;
}

.entry-exit-palette {
  width: 245px;
  height: 80px;
}

.categories-palette {
  width: 244px;
  height: 370px;
}
</style>
<script>
// @ is an alias to /src
import * as go from "gojs";
//import AppError from "@/utils/AppError.js";

let AllowTopLevel = false;
let CellSize = new go.Size(50, 50);
let myDiagram = null;
go.Diagram.licenseKey =
  "73f944e5b46f31b700ca0d2b113f69ee1bb37b3a9e841af95e5342f5ee0c68402a98ed7c58d58fc1d4aa4ffe197bc28d8ec16d2d855c0368b336d2db10e5d1f9b03573b11c0f428df45022979efb2ba7fb2a72fa91e773a78d2d8ff0edac969c09eff6d518981cab2f2d5637562cff4ba5ebda7afa01d346746d9f";

export default {
  name: "planogram-analytics",

  props: {
    planogram: {
      type: Object,
      required: true,
    },
    nodes: {
      type: Array,
      required: true,
    },
  },

  mounted: function () {
    this.getStore();
    this.getLoadedDiagram();
    this.updateData();
  },

  data() {
    return {
      loadedDiagram: null,
    };
  },

  methods: {
    /**
     * Get the loaded planogram.
     * @public
     */
    getLoadedDiagram() {
      this.$store.dispatch("util/startLoading");
      this.loadedDiagram = this.planogram.planogram;
      this.buildPlanogram();
    },

    /**
     * Update data cells for journey.
     * @public
     */
    updateData() {
      myDiagram.model.addNodeDataCollection(this.nodes);
      this.$store.dispatch("util/stopLoading");
    },

    /**
     * Get the selected store.
     * @public
     */
    getStore() {
      this.store = JSON.parse(localStorage.getItem("store"));
    },

    /**
     * Build diagram inside 'myDiagramDiv'.
     * @public
     */
    buildPlanogram() {
      const $ = go.GraphObject.make;

      myDiagram = $(go.Diagram, this.planogram.name, {
        grid: $(
          go.Panel,
          "Grid",
          { gridCellSize: CellSize, background: "white" },
          $(go.Shape, "LineH", { stroke: "lightgray" }),
          $(go.Shape, "LineV", { stroke: "lightgray" })
        ),
        "draggingTool.isGridSnapEnabled": true,
        "draggingTool.gridSnapCellSpot": go.Spot.Center,
        "resizingTool.isGridSnapEnabled": true,
        "animationManager.isEnabled": false,
        "undoManager.isEnabled": true,
        isReadOnly: true,
      });

      if (this.loadedDiagram != null) {
        // Getting data from json
        myDiagram.model = go.Model.fromJson(this.loadedDiagram);
      }

      // Regular Nodes represent items to be put onto racks.

      // Normal template used for category and entry/exit nodes.
      var normalTemplate = $(
        go.Node,
        "Auto",
        {
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        // Always save/load the point that is the top-left corner of the node.
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            stroke: "#9b9b9b",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        ),
        $(
          go.TextBlock,
          { alignment: go.Spot.Center, font: "bold 14px sans-serif" },
          new go.Binding("text", "key")
        )
      );
      // end Node

      // Heatmap Template used for heatmap nodes.
      var heatMapTemplate = $(
        go.Node,
        "Auto",
        {
          resizeObjectName: "HEATMAP",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            stroke: "transparent",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        )
      );

      // Journey Template used for journey nodes.
      var journeyTemplate = $(
        go.Node,
        "Auto",
        {
          resizeObjectName: "JOURNEY",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            stroke: "#9b9b9b",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        )
      );

      // Segments Template used for segment nodes.
      var segmentsTemplate = $(
        go.Node,
        "Auto",
        {
          resizeObjectName: "SEGMENT",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),

          // Provide a visual warning about dropping anything onto an "item".
          mouseDragEnter: function (e, node) {
            e.handled = true;
            node.findObject("SHAPE").fill = "red";
            e.diagram.currentCursor = "not-allowed";
            highlightGroup(node.containingGroup, false);
          },
          mouseDragLeave: function (e, node) {
            node.updateTargetBindings();
          },
          mouseDrop: function (e, node) {
            // Disallow dropping anything onto an "item".
            node.diagram.currentTool.doCancel();
          },
        },

        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),

        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: "white",
            minSize: CellSize,
            desiredSize: CellSize,
          },
          new go.Binding("fill", "color"),
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          )
        )
      );

      var templmap = new go.Map();

      // Add all templates to diagram.
      templmap.add("normal", normalTemplate);
      templmap.add("heatmap", heatMapTemplate);
      templmap.add("journey", journeyTemplate);
      templmap.add("segments", segmentsTemplate);
      templmap.add("", myDiagram.nodeTemplate);

      myDiagram.nodeTemplateMap = templmap;

      // Groups represent racks where items (Nodes) can be placed.
      // Groups provide feedback when the user drags nodes onto them.
      function highlightGroup(grp, show) {
        if (!grp) return false;
        // Check that the drop may really happen into the Group.
        var tool = grp.diagram.toolManager.draggingTool;
        grp.isHighlighted = show && grp.canAddMembers(tool.draggingParts);
        return grp.isHighlighted;
      }

      var groupFill = "rgb(115, 115, 115, 0.4)";
      var groupStroke = "gray";
      var dropFill = "rgba(128,255,255,0.2)";
      var dropStroke = "blue";

      myDiagram.groupTemplate = $(
        go.Group,
        {
          layerName: "Background",
          resizable: true,
          resizeObjectName: "SHAPE",
          locationSpot: new go.Spot(
            0,
            0,
            CellSize.width / 2,
            CellSize.height / 2
          ),
        },
        new go.Binding("position", "pos", go.Point.parse).makeTwoWay(
          go.Point.stringify
        ),
        {
          // What to do when a drag-over or a drag-drop occurs on a Group.
          mouseDragEnter: function (e, grp) {
            if (!highlightGroup(grp, true))
              e.diagram.currentCursor = "not-allowed";
            else e.diagram.currentCursor = "";
          },
          mouseDragLeave: function (e, grp) {
            highlightGroup(grp, false);
          },
          mouseDrop: function (e, grp) {
            var ok = grp.addMembers(grp.diagram.selection, true);
            if (!ok) grp.diagram.currentTool.doCancel();
          },
        },
        $(
          go.Shape,
          "Rectangle",
          {
            name: "SHAPE",
            fill: groupFill,
            stroke: groupStroke,
            minSize: new go.Size(CellSize.width, CellSize.height),
          },
          new go.Binding("desiredSize", "size", go.Size.parse).makeTwoWay(
            go.Size.stringify
          ),
          new go.Binding("fill", "isHighlighted", function (h) {
            return h ? dropFill : groupFill;
          }).ofObject(),
          new go.Binding("stroke", "isHighlighted", function (h) {
            return h ? dropStroke : groupStroke;
          }).ofObject()
        )
      );

      // Decide what kinds of Parts can be added to a Group
      myDiagram.commandHandler.memberValidation = function (grp, node) {
        // cannot add Groups to Groups
        if (grp instanceof go.Group && node instanceof go.Group) return false;
        return true;
      };

      // What to do when a drag-drop occurs in the Diagram's background
      myDiagram.mouseDragOver = function (e) {
        if (!AllowTopLevel) {
          // OK to drop a group anywhere or any Node that is a member of a dragged Group
          var tool = e.diagram.toolManager.draggingTool;
          if (
            !tool.draggingParts.all(function (p) {
              return (
                p instanceof go.Group ||
                (!p.isTopLevel &&
                  tool.draggingParts.contains(p.containingGroup))
              );
            })
          ) {
            e.diagram.currentCursor = "not-allowed";
          } else {
            e.diagram.currentCursor = "";
          }
        }
      };

      myDiagram.mouseDrop = function (e) {
        if (AllowTopLevel) {
          // When the selection is dropped in the diagram's background,
          // Make sure the selected Parts no longer belong to any Group
          if (
            !e.diagram.commandHandler.addTopLevelParts(
              e.diagram.selection,
              true
            )
          ) {
            e.diagram.currentTool.doCancel();
          }
        } else {
          // Disallow dropping any regular nodes onto the background, but allow dropping "racks",
          // Including any selected member nodes
          if (
            !e.diagram.selection.all(function (p) {
              return (
                p instanceof go.Group ||
                (!p.isTopLevel && p.containingGroup.isSelected)
              );
            })
          ) {
            e.diagram.currentTool.doCancel();
          }
        }
      };
    },
  },
};
</script>
